import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import {
  Container,
  CircularProgress,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  Card,
  Paper,
  CardContent,
  Switch,
  Grid,
  Pagination,
} from '@mui/material';
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';
import { MdOutlineDeleteForever } from "react-icons/md";
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './UserDevice.css';

export default function UserDevice() {
  const [searchResults, setSearchResults] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [searchKey, setSearchKey] = useState('');
  const [searchType, setSearchType] = useState('deviceid');
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [usermap, setUsermap] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleLoading, setToggleLoading] = useState(false); // Loader for toggle switch
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const Apiurl = process.env.REACT_APP_BASE_URL
  const roleSuper = JSON.parse(localStorage.getItem('data')).role === 'ROLE_SUPER';
  const roleAdmin = JSON.parse(localStorage.getItem('data')).role === 'ROLE_ADMIN';

  useEffect(() => {
    getData();
  }, [reducerValue]);

  const getData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('data')).token;
      const uid = JSON.parse(localStorage.getItem('data')).userid;
      const role = JSON.parse(localStorage.getItem('data')).role;

      if (role === 'ROLE_SUPER') {
        const { data } = await axios.get(`${Apiurl}/api/deviceusermapping`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsermap(data.sort((a, b) => a.deviceid.localeCompare(b.deviceid))); // Sort in ascending order
        setTotalItems(data.length);
      } else {
        const response = await axios.get(`${Apiurl}/api/deviceusermapping/userid=${uid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDevices(response.data.sort((a, b) => a.deviceid.localeCompare(b.deviceid))); // Sort in ascending order
        setTotalItems(response.data.length);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error fetching data');
    }
  };

  const onClickhandler = async (e, id) => {
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      const response = await axios.post(
        `${Apiurl}/api/deviceusermapping/DeviceUserMappingUpdate/id=${id}/value=${!checked}`,
        user,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update the local state based on the response
      setDevices((prevDevices) =>
        prevDevices.map((device) =>
          device.id === id ? { ...device, disabled: !checked } : device
        )
      );
      toast.success('Device status updated successfully');
    } catch (error) {
      console.error('Error updating device status:', error);
      toast.error('Error updating device status');
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const onClicksubmit = async (e, aid, adeviceid) => {
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      await axios({
        url: `${Apiurl}/api/deviceusermapping`,
        method: 'delete',
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: aid,
          deviceid: adeviceid,
        },
      });
      toast('Deleted successfully');
      getData();
      forceUpdate();
    } catch (error) {
      console.error('Error deleting device user mapping:', error);
      toast.error('Error deleting device user mapping');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKey(value);
    filterResults(value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
    setSearchKey('');
    setSearchResults([]);
  };

  const filterResults = (searchKey) => {
    const filtered = usermap.filter((item) =>
      searchType === 'deviceid'
        ? item.deviceid.toLowerCase().includes(searchKey.toLowerCase())
        : item.accountlink.toLowerCase().includes(searchKey.toLowerCase())
    );
    setSearchResults(filtered.sort((a, b) => a.deviceid.localeCompare(b.deviceid))); // Sort in ascending order
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const paginatedDevices = Array.isArray(devices)
    ? devices.slice(startIndex, endIndex)
    : [];
  const paginatedUsermap = Array.isArray(searchResults) && searchKey
    ? searchResults.slice(startIndex, endIndex)
    : usermap.slice(startIndex, endIndex);

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', py: 1 }}>
      <Container>
        <ToastContainer />
        {roleAdmin && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Device User Details
            </Typography>
            <Grid container spacing={2}>
              {paginatedDevices.map((agent) => (
                <Grid item key={agent.id} xs={12} sm={6} md={3}>
                  <Card>
                    <CardContent>
                      <Typography variant="body1" gutterBottom>Device ID: {agent.deviceid}</Typography>
                      {toggleLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Switch
                          checked={!agent.disabled}
                          onChange={(e) => onClickhandler(e, agent.id)}
                          value={agent.id}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {roleSuper && (
          <Box my={4}>
            <Paper>
              <Box p={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h5">Device User Mapping Details</Typography>
                  <Link to="/users/AddDeviceMaping" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary" style={{ width: '200px', height: "100%" }} startIcon={<AddIcon />}>
                      Add Device User
                    </Button>
                  </Link>
                </Box>
                <Box mt={3} display="flex" alignItems="center" gap={2}>
                  <Select value={searchType} onChange={handleSearchTypeChange}>
                    <MenuItem value="deviceid">Device ID</MenuItem>
                    <MenuItem value="accountlink">Account</MenuItem>
                  </Select>
                  <TextField
                    value={searchKey}
                    onChange={handleInputChange}
                    placeholder={`Search by ${searchType === 'deviceid' ? 'Device ID' : 'Account Link'}...`}
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    {paginatedUsermap.map((agent) => (
                      <Grid item key={agent.id} xs={12} sm={6} md={3}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>Device ID: {agent.deviceid}</Typography>
                            <Typography variant="h7" gutterBottom>Account Link: {agent.accountlink}</Typography>
                            <IconButton
                              style={{ color: 'red' }}
                              color="primary"
                              onClick={(e) => onClicksubmit(e, agent.id, agent.deviceid)}
                            >
                              <MdOutlineDeleteForever />
                            </IconButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
           
              </Box>
            </Paper>
                 <Box mt={3} display="flex" justifyContent="center">
                  <Pagination
                    count={Math.ceil(totalItems / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
          </Box>
          
        )}
      </Container>
    </Box>
  );
}
