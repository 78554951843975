import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../api/baseurl';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  Box, Container, Grid, Card, CardContent, Typography, Button, CircularProgress, Switch,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@mui/material';
import Pagination from '../Component/Pagination'; 
import { ImSearch } from "react-icons/im";
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';

function Device() {
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const [device, setDevice] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("data")).token;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    try {
      const { data } = await axios.get(`${Apiurl}/api/device`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setDevice(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    const filtered = device.filter(item => {
      const lowerValue = value.toLowerCase();
      return (
        (item.id && item.id.toString().toLowerCase().includes(lowerValue)) ||
        (item.device && item.device.toLowerCase().includes(lowerValue)) ||
        (item.serialno && item.serialno.toLowerCase().includes(lowerValue)) ||
        (item.mobileno && item.mobileno.toLowerCase().includes(lowerValue))
      );
    });

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handleOpen = (device) => {
    setSelectedDevice(device);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDevice(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${Apiurl}/api/device`, {
        headers: { Authorization: `Bearer ${token}` },
        data: selectedDevice,
      });
      setDevice(device.filter((item) => item.id !== selectedDevice.id));
      toast.success('Deleted successfully');
      handleClose();
    } catch (error) {
      console.error('Error deleting device:', error);
      toast.error('Failed to delete');
    }
  };

  const onClickhandler = async (e, id) => {
    const checked = e.target.checked;
    setLoading(true);
    try {
      await axios.get(`${Apiurl}/api/device/deviceUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      getData();
    } catch (error) {
      console.error('Error updating device status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedDevices = filteredData.slice(startIndex, endIndex);

  return (
    <Box p={3}>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <Container>
          <Box mb={3} display="flex" alignItems="center" sx={{ gap: 1 }}>
            <ImSearch style={{ marginRight: '8px', fontSize: "25px" }} />
            <TextField
              label="Search by Device ID, Serial No, or Mobile No"
              variant="outlined"
              value={searchInput}
              onChange={handleSearchInputChange}
              sx={{ width: "300px" }}
            />
            {`${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_SUPER" && (
              <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                <Link to="/users/AddDevice" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" sx={{ width: "140px", height: "90%" }} startIcon={<AddIcon />}>
                    Add Device
                  </Button>
                </Link>
              </Box>
            )}
          </Box>

          <Grid container spacing={3}>
            {paginatedDevices.map(agent => (
              <Grid item xs={12} sm={6} md={4} key={agent.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Device ID: {agent.device}</Typography>
                    <Typography variant="body2">Serial No: {agent.serialno}</Typography>
                    <Typography variant="body2">Mobile No: {agent.mobileno}</Typography>
                    <Box mt={2} display="flex" justifyContent="space-between">
                      <Switch checked={!agent.disabled} onChange={(e) => onClickhandler(e, agent.id)} />
                      <Box>
                        <Link to={`/users/Editdevice/${agent.id}`} style={{ textDecoration: "none", marginRight: '10px' }}>
                          <FaEdit style={{ fontSize: "1.5em" }} />
                        </Link>
                        <MdDeleteForever
                          style={{ color: 'red', fontSize: '1.5em', cursor: 'pointer' }}
                          onClick={() => handleOpen(agent)}
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Pagination
            data={filteredData.slice().reverse()}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            style={{ justifyContent: "flex-end", marginTop: "20px" }}
          />
        </Container>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you delete this device, you will lose the data completely. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Device;
