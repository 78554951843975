import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import {
    Container,
    Tabs,
    Tab,
    Box,
    Typography,
    Card,
    CardContent,
    TextField,
    Button,
    Grid,
    Avatar,
    CircularProgress,
    IconButton
} from '@mui/material';
import { IoMdArrowRoundBack } from "react-icons/io";
import "react-toastify/dist/ReactToastify.css";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} params={params} navigate={navigate} />;
    }
    return ComponentWithRouterProp;
}

function AccountBio(props) {
    const Apiurl = process.env.REACT_APP_BASE_URL;
    const location = useLocation();
    const username = location.state?.username || '';

    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const [account, setAccounts] = useState([]);
    

    const [user, setUsers] = useState([]);
    const [adminsubscriptionbilling, setAdminsubscriptionbilling] = useState({
        id: '',
        subcode: '',
        subdate: '',
        subUsername: '',
        transactionLink: '',
        username: username,
        expires: '',
    });
    console.log(adminsubscriptionbilling)
    const [agents, setAgents] = useState({
        userId: '',
        firstname: '',
        lastname: '',
        phone: '',
        email: username, // Set email from username
        company: '',
        country: '',
        image: null,
        imageMeta: '',
    });
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem('data'))?.token;


    const fecthData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Apiurl}/api/accounts/username=${username}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAccounts(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching account bio:', error);
            toast.error('Failed to fetch account bio');
        } finally {
            setLoading(false);
        }
    };


    const getData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Apiurl}/api/account-bio/email=${username}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAgents(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching account bio:', error);
            toast.error('Failed to fetch account bio');
        } finally {
            setLoading(false);
        }
    };




    const getData1 = async () => {
        setLoading(true);
        try {
            const usersub = await axios.get(`${Apiurl}/api/user/subscription/username=${username}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (Array.isArray(usersub.data) && usersub.data.length > 0) {
                setAdminsubscriptionbilling(usersub.data[0]);
            }
        } catch (error) {
            console.error('Error fetching subscription:', error);
            toast.error('Failed to fetch subscription');
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const [metadata, content] = reader.result.split(',');
            setAgents({
                ...agents,
                imageMeta: metadata,
                image: content
            });
        };

        reader.onerror = (error) => {
            console.error('Error reading file:', error);
            toast.error('Failed to read image file');
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (event, field) => {
        setAgents({ ...agents, [field]: event.target.value });
    };

    const handleChangeSubscriptionBilling = (event, field) => {
        setAdminsubscriptionbilling({
            ...adminsubscriptionbilling,
            [field]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            let response;
            const payload = {
                ...agents,
                email: username, // Ensure the email is set from username
            };

            if (agents.username) {
                response = await axios.post(`${Apiurl}/api/account-bio/update`, payload, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                toast.success('Profile Updated');
            } else {
                response = await axios.post(`${Apiurl}/api/account-bio/create`, payload, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                toast.success('Profile Created');
            }

            localStorage.setItem('profile', JSON.stringify(response.data));

        } catch (error) {
            console.error('Error saving profile:', error);
            toast.error('Failed to save profile');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit1 = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${Apiurl}/api/user/subscription/update`, adminsubscriptionbilling, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success('Subscription Updated');
            setAdminsubscriptionbilling(response.data);
        } catch (error) {
            console.error('Error updating subscription:', error);
            toast.error('Failed to update subscription');
        } finally {
            setLoading(false);
        }
    };

    const onClickHandler1 = async () => {
        

        try { 
            const res = await axios.get(`${Apiurl}/api/deviceusermapping/userid=${account.id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserDetails(res.data);
            console.log(res.data);
        } catch (error) {
            console.error("Error fetching user details:", error);
            setUserDetails([]);
            toast.error('Failed to fetch user details');
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);

        // Fetch user details when the "User Details" tab is selected
        if (newValue === 2) {
            onClickHandler1(agents.username); // Pass the userId from agents to fetch user details
        }
    };

    // Component for displaying each device in a separate card
    const DeviceCard = ({ device }) => {
        return (
            
            <Card sx={{ mb: 2 }}>
                <CardContent>
                {/* <Typography>Account Email: {device.accountlink}</Typography> */}
                    <Typography variant="h6">Device ID: {device.deviceid}</Typography>
                    
                </CardContent>
            </Card>
        );
    };

    // Updated UserDetailsDialog to use DeviceCard for each device
    const UserDetailsDialog = ({ userdata }) => {
        return (
            <Box p={3}>
                
                <Typography variant="h6" gutterBottom>
                    User Details
                </Typography>
                
                <Grid container spacing={2}>
                    {userdata.length > 0 ? (
                        userdata.map((device, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <DeviceCard device={device} />
                            </Grid>
                        ))
                    ) : (
                        <Typography>No data available</Typography>
                    )}
                </Grid>
            </Box>
        );
    };

    useEffect(() => {
        getData();
        getData1();
        fecthData();
    }, []);

    return (
        <Container>
            <ToastContainer />
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ width: '100%', mt: 3 }}>
                    <Tabs value={activeTab} onChange={handleTabChange} centered>
                        <Tab label="User Profile" />
                        <Tab label="Subscription" />
                        <Tab label="User Details" />
                    </Tabs>
                    <IconButton onClick={() => navigate(-1)} color="primary" style={{width:"80px", fontSize:"30px"}}>
                <IoMdArrowRoundBack />
              </IconButton>
                    {activeTab === 0 && (
                        <Box p={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                Profile Image
                                            </Typography>
                                            <Box display="flex" flexDirection="column" alignItems="center">
                                                {agents.image && (
                                                    <Avatar
                                                        src={`${agents?.imageMeta},${agents?.image}`}
                                                        alt="Profile"
                                                        sx={{ width: 150, height: 150, mb: 2 }}
                                                    />
                                                )}
                                                <input
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="image-upload"
                                                    type="file"
                                                    onChange={handleImageChange}
                                                />
                                                <label htmlFor="image-upload">
                                                    <Button variant="contained" component="span">
                                                        Upload Image
                                                    </Button>
                                                </label>
                                                <Typography variant="caption" color="textSecondary">
                                                    JPG or PNG no larger than 5 MB
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                Account Details
                                            </Typography>
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="First Name"
                                                            variant="outlined"
                                                            value={agents.firstname}
                                                            onChange={(event) => handleChange(event, 'firstname')}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Last Name"
                                                            variant="outlined"
                                                            value={agents.lastname}
                                                            onChange={(event) => handleChange(event, 'lastname')}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Phone"
                                                            variant="outlined"
                                                            value={agents.phone}
                                                            onChange={(event) => handleChange(event, 'phone')}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Email"
                                                            variant="outlined"
                                                            value={agents.email}
                                                            onChange={(event) => handleChange(event, 'email')}
                                                            readinly
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Company"
                                                            variant="outlined"
                                                            value={agents.company}
                                                            onChange={(event) => handleChange(event, 'company')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Country"
                                                            variant="outlined"
                                                            value={agents.country}
                                                            onChange={(event) => handleChange(event, 'country')}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Box mt={2}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        disabled={loading}
                                                    >
                                                        {loading ? 'Saving...' : 'Save Changes'}
                                                    </Button>
                                                </Box>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    {activeTab === 1 && (
                        <Box p={3}>
                            <Card>
                            <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Subscription Details
                            </Typography>
                            <form onSubmit={handleSubmit1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Subscription Code"
                                            variant="outlined"
                                            value={adminsubscriptionbilling.subcode}
                                            onChange={(event) => handleChangeSubscriptionBilling(event, 'subcode')}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Subscription Date"
                                            variant="outlined"
                                            value={adminsubscriptionbilling.subdate}
                                            onChange={(event) => handleChangeSubscriptionBilling(event, 'subdate')}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Username"
                                            variant="outlined"
                                            value={adminsubscriptionbilling.subUsername}
                                            onChange={(event) => handleChangeSubscriptionBilling(event, 'subUsername')}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Transaction Link"
                                            variant="outlined"
                                            value={adminsubscriptionbilling.transactionLink}
                                            onChange={(event) => handleChangeSubscriptionBilling(event, 'transactionLink')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Expires"
                                            variant="outlined"
                                            value={adminsubscriptionbilling.expires}
                                            onChange={(event) => handleChangeSubscriptionBilling(event, 'expires')}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={loading}
                                    >
                                        {loading ? 'Saving...' : 'Save Changes'}
                                    </Button>
                                </Box>
                            </form>
                            </CardContent>
                            </Card>
                        </Box>
                    )}
                     {activeTab === 2 && <UserDetailsDialog userdata={userDetails} />}
                    
                </Box>
            )}
        </Container>
    );
}

export default withRouter(AccountBio);
