import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from '../api/baseurl';
import CreatableSelect from 'react-select/creatable';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Select, MenuItem, Card, CardContent, Typography } from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';

function Subscriptionmodule() {
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [subfeature, setSubfeature] = useState([]);
  const [subfeaturedropdown, setSubfeaturedropdown] = useState([]);
  const [regions, setRegions] = useState([]); // State to hold subscription regions
  const [models, setModels] = useState([]); // State to hold subscription models
  const [module, setModule] = useState({
    submodel: "",
    region: "",
    cost: "",
    symbol: "",
    features: "",
  });

  const [value, setValue] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("data")).token;

  const handleChange1 = (event, field) => {
    let actualValue = event.target.value;
    setModule({ ...module, [field]: actualValue });
  };

  // Updated handleSubmit function
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const body = {
      ...module,
      features: value.map((v1) => ({
        features: v1.label
      }))
    };

    try {
      await axios.post(`${Apiurl}/api/subscription-model/create`, body, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      toast("Subscription model created successfully");

      await getData(); 

      setOpen(false); 
    } catch (error) {
      toast("The Model name must be unique for the region");
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = (inputValue) => {
    const newValue = [...subfeaturedropdown, { value: inputValue, label: inputValue }];
    setSubfeaturedropdown(newValue);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const [regionResponse, featuresResponse, modelResponse] = await Promise.all([
        axios.get(`${Apiurl}/api/subscription-region`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${Apiurl}/api/subscription-features`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${Apiurl}/api/subscription-model`, { headers: { Authorization: `Bearer ${token}` } })
      ]);

      setRegions(regionResponse.data); // Set fetched regions
      setSubfeature(featuresResponse.data);
      setSubfeaturedropdown(featuresResponse.data.map((sf) => ({ value: sf.features, label: sf.features })));
      setModels(modelResponse.data); // Set fetched models
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon />}>
          Add Subscription
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Subscription Module</DialogTitle>
        <DialogContent>
          {loading && (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {!loading && (
            <form onSubmit={handleSubmit}>
              <TextField
                margin="dense"
                label="Submodel"
                fullWidth
                value={module.submodel}
                onChange={(e) => handleChange1(e, "submodel")}
              />
              <Select
                fullWidth
                value={module.region}
                onChange={(e) => handleChange1(e, "region")}
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="">
                  <em>Select region</em>
                </MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region.id} value={region.region}>
                    {region.region}
                  </MenuItem>
                ))}
              </Select>

              <TextField
                margin="dense"
                label="Cost"
                fullWidth
                value={module.cost}
                onChange={(e) => handleChange1(e, "cost")}
              />
              <Select
                fullWidth
                value={module.symbol}
                onChange={(e) => handleChange1(e, "symbol")}
                displayEmpty
                MenuProps={{
                  PaperProps:{
                    style:{
                      maxHeight:200,
                      overflowY:'auto',
                    },
                  },
                }}
              >
                <MenuItem value=""><em>Select symbol</em></MenuItem>
                {regions.map((symbol)=>(
                  <MenuItem key={symbol.id} value={symbol.symbol}>{symbol.symbol}</MenuItem>
                ))}
              </Select>
              <br/>
              <CreatableSelect
                onChange={(newValue) => setValue(newValue)}
                isClearable
                isMulti
                MenuProps={{
                  PaperProps:{
                    style:{
                      maxHeight:200,
                      overflowY:'auto',
                    },
                  },
                }}
                options={subfeaturedropdown}
              />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Card Display Section */}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px' }}>
        {models.map((model) => (
          <Card key={model.id} style={{ width: '300px' }}>
            <CardContent>
              <Typography variant="h6">{model.submodel}</Typography>
              <Typography variant="body2">Region: {model.region}</Typography>
              <Typography variant="body2">Cost: {model.cost}</Typography>
              <Typography variant="body2">Symbol: {model.symbol}</Typography>
              {/* <Typography variant="body2">Features: {model.features.join(", ")}</Typography> */}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Subscriptionmodule;
