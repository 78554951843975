import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ImSearch } from "react-icons/im";
import { Container, Grid, Card, CardContent, Typography, Button, CircularProgress, Box, TextField, Switch } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';

function ExternalAccount() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('data'));
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    getData();
  }, [currentPage]);

  const getData = async () => {
    setLoading(true);
    const token = user.token;

    try {
      const { data } = await axios.get(`${Apiurl}/api/externalaccount/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    const filtered = data.filter(item => {
      const idStr = item.id ? item.id.toString().toLowerCase() : '';
      return (
        idStr.includes(value.toLowerCase()) ||
        (item.username && item.username.toLowerCase().includes(value.toLowerCase()))
      );
    });

    setFilteredData(filtered);
  };

  const handleClick = () => {
    navigate(`/users/Account`);
  };

  const isValid = (validity) => {
    const currentDate = new Date();
    const validityDate = new Date(validity);
    return validityDate >= currentDate;
  };

  // Handler to toggle validity
  const handleToggleValidity = async (id, currentValidity) => {
    setLoading(true);
    const token = user.token;

    // Toggle validity status
    const newValidity = currentValidity ? null : new Date().toISOString(); // Adjust this logic based on how you manage validity

    try {
      await axios.patch(`${Apiurl}/api/externalaccount/${id}/`, {
        validity: newValidity
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Update the data state with the new validity status
      setData(prevData => 
        prevData.map(item => 
          item.id === id ? { ...item, validity: newValidity } : item
        )
      );
      setFilteredData(prevData => 
        prevData.map(item => 
          item.id === id ? { ...item, validity: newValidity } : item
        )
      );
    } catch (error) {
      console.error('Error updating validity:', error);
    } finally {
      setLoading(false);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = Array.isArray(filteredData)
    ? filteredData.slice(startIndex, endIndex)
    : [];

  return (
    <Container>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Box mt={3}>
          <Box display="flex" alignItems="center" mb={3} sx={{ gap: 1 }}>
            <ImSearch style={{ marginRight: '8px', fontSize: "25px" }} />
            <TextField
              label="Search by Email or Username"
              variant="outlined"
              value={searchInput}
              onChange={handleSearchInputChange}
              sx={{
                width: "250px",
                marginRight: 'auto'
              }}
            />
            <Link to="/users/Account" style={{ textDecoration: "none" }}>
              <Button variant="contained" onClick={handleClick} color="primary" sx={{ width: "150px", fontSize: "12px", height: "40px" }} startIcon={<AddIcon />}>
                Add Account
              </Button>
            </Link>
          </Box>

          <Grid container spacing={3}>
            {paginatedData.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {item.appname}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Id:</strong> {item.id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Validity:</strong> {item.validity}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        wordBreak: 'break-all',
                        whiteSpace: 'normal',
                      }}
                    >
                      <strong>Token:</strong> {item.token}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Username:</strong> <span style={{ color: 'blue' }}>{item.username}</span>
                    </Typography>

                    {/* Toggle Switch for Validity */}
                    <Typography variant="body2" color="text.secondary">
                      <strong>Account Active:</strong>
                    </Typography>
                    <Switch
                      checked={isValid(item.validity)}
                      color="primary"
                      inputProps={{ 'aria-label': 'Validity Toggle' }}
                      onChange={() => handleToggleValidity(item.id, isValid(item.validity))}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Pagination
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Grid>
        </Box>
      )}
    </Container>
  );
}

export default ExternalAccount;
