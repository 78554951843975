import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);
  const Apiurl = process.env.REACT_APP_BASE_URL;
 

  useEffect(() => {
    const fetchNotifications = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      
  const username = JSON.parse(localStorage.getItem("data")).username;
      try {
        const { data } = await axios.get(`${Apiurl}/api/user/notifications/username=${username}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setNotifications(data);
        const unreadNotifications = data.filter(notification => !notification.read);
        setUnseenCount(unreadNotifications.length);
      } catch (error) {
        console.error("Error fetching notifications", error);
      }
    };

    fetchNotifications();
  }, [Apiurl]);

  const markNotificationAsRead = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
  const username = JSON.parse(localStorage.getItem("data")).username;

    try {
      await axios.post(`${Apiurl}/api/user/notifications/update`, 
        { id, read: true }, 
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotifications(prevNotifications => 
        prevNotifications.map(notification => 
          notification.id === id ? { ...notification, read: true } : notification
        )
      );
      setUnseenCount(prevCount => prevCount - 1);
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  return (
    <NotificationContext.Provider value={{ notifications, unseenCount, markNotificationAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
};
