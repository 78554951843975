import React, { useState, useEffect } from 'react';
import axios from '../api/baseurl';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Grid,
    Pagination,
} from '@mui/material';

const Permission = () => {
    const Apiurl = process.env.REACT_APP_BASE_URL;
    const [error, setError] = useState(null);
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("data")).token;

        axios.get(`${Apiurl}/api/permissions`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(response => response.data)
            .then((result) => {
                setAgents(result);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [Apiurl]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.isArray(agents)
        ? agents.slice(indexOfFirstItem, indexOfLastItem)
        : [];

    if (error) {
        return <Typography color="error">Error: {error.message}</Typography>;
    } else if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <CircularProgress />
            </div>
        );
    } else {
        return (
            <Container maxWidth="lg">
                <Typography variant="h5" gutterBottom>Permissions Details</Typography>
                <br/>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Permission</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Permission Code</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems.map(agent => (
                                <TableRow key={agent.id}>
                                    <TableCell>{agent.id}</TableCell>
                                    <TableCell>{agent.permission}</TableCell>
                                    <TableCell>{agent.description}</TableCell>
                                    <TableCell>{agent.permissionCode}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container justifyContent="center" sx={{ mt: 2 }}>
                    <Pagination
                        count={Math.ceil(agents.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                        color="primary"
                    />
                </Grid>
            </Container>
        );
    }
};

export default Permission;
