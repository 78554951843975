import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Typography, Box ,CircularProgress, colors} from '@mui/material';


function Dashboard(){
    const [accounts,setAccounts]=useState(0);
    const[externalAccount,setExternalAccount]=useState(0);
    const[AccExternlAccount,setAccExternalAccount]=useState(0);
    const [totaldevice,setTotaldevice]=useState(0);
    const[enableddevice,setEnableddevice]=useState(0);
    const [linkedDevice,setLinkedDevice]=useState(0);
    const [loading,setLoading]=useState(true);
    const Apiurl = process.env.REACT_APP_BASE_URL;

    useEffect(()=>{
        getData();
        fetchData();
        fetchActiveData();
        fetchDeviceData();
        fetchLinkedDeviceData();
        fetchEnabledDeviceData();
    },[]);  

    const getData = async () => {
        const storedData = JSON.parse(localStorage.getItem('data'));
        if (storedData && storedData.token && storedData.userid) {
          const token = storedData.token;
          const uid = storedData.userid;
    
          try {
            const response = await axios.get(`${Apiurl}/api/accounts`, {
              headers: { Authorization: `Bearer ${token}` },
            });
    
            setAccounts(response.data.length);
           setLoading(false);
          
          } catch (error) {
            console.error('Error fetching data', error);
          } finally {
            setLoading(false); 
          }
        }
          else {
            console.error('User data not found in localStorage');
            setLoading(false);
          }
        } 

        const fetchData = async () => {
            const storedData = JSON.parse(localStorage.getItem('data'));
            if (storedData && storedData.token && storedData.userid) {
              const token = storedData.token;
            
        
              try {
                const response = await axios.get(`${Apiurl}/api/externalaccount/`, {
                  headers: { Authorization: `Bearer ${token}` },
                });
        
                setExternalAccount(response.data.length);
               setLoading(false);
              
              } catch (error) {
                console.error('Error fetching data', error);
              } finally {
                setLoading(false); 
              }
            }
              else {
                console.error('User data not found in localStorage');
                setLoading(false);
              }
            } 

            const fetchActiveData = async () => {
                const storedData = JSON.parse(localStorage.getItem('data'));
                if (storedData && storedData.token && storedData.userid) {
                  const token = storedData.token;
                
            
                  try {
                    const response = await axios.get(`${Apiurl}/api/externalaccount/ActiveAcc`, {
                      headers: { Authorization: `Bearer ${token}` },
                    });
            
                    setAccExternalAccount(response.data.length);
                   setLoading(false);
                  
                  } catch (error) {
                    console.error('Error fetching data', error);
                  } finally {
                    setLoading(false); 
                  }
                }
                  else {
                    console.error('User data not found in localStorage');
                    setLoading(false);
                  }
                } 

               

                   const fetchDeviceData = async () => {
                const storedData = JSON.parse(localStorage.getItem('data'));
                if (storedData && storedData.token && storedData.userid) {
                  const token = storedData.token;
                
            
                  try {
                    const response = await axios.get(`${Apiurl}/api/device`, {
                      headers: { Authorization: `Bearer ${token}` },
                    });
            
                    setTotaldevice(response.data.length);
                   setLoading(false);
                  
                  } catch (error) {
                    console.error('Error fetching data', error);
                  } finally {
                    setLoading(false); 
                  }
                }
                  else {
                    console.error('User data not found in localStorage');
                    setLoading(false);
                  }
                } 
                const fetchLinkedDeviceData = async () => {
                  const storedData = JSON.parse(localStorage.getItem('data'));
                  if (storedData && storedData.token && storedData.userid) {
                    const token = storedData.token;
                  
              
                    try {
                      const response = await axios.get(`${Apiurl}/api/deviceusermapping`, {
                        headers: { Authorization: `Bearer ${token}` },
                      });
              
                      setLinkedDevice(response.data.length);
                     setLoading(false);
                    
                    } catch (error) {
                      console.error('Error fetching data', error);
                    } finally {
                      setLoading(false); 
                    }
                  }
                    else {
                      console.error('User data not found in localStorage');
                      setLoading(false);
                    }
                  } 


                  const fetchEnabledDeviceData = async () => {
                    const storedData = JSON.parse(localStorage.getItem('data'));
                    if (storedData && storedData.token && storedData.userid) {
                      const token = storedData.token;
                    
                
                      try {
                        const response = await axios.get(`${Apiurl}/api/deviceusermapping/enabledevice`, {
                          headers: { Authorization: `Bearer ${token}` },
                        });
                
                        setEnableddevice(response.data.length);
                       setLoading(false);
                      
                      } catch (error) {
                        console.error('Error fetching data', error);
                      } finally {
                        setLoading(false); 
                      }
                    }
                      else {
                        console.error('User data not found in localStorage');
                        setLoading(false);
                      }
               } 
        
      
    return(
        <div>
     
        {loading ? (
          <Box className="loading-container">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="dashboard-container">
            <Box className="row">
              <Card className="dashboard-card  col-3"   style={{ backgroundColor: "violet"} }>
                <Typography variant="h5" component="h9">
                  Total no of admins: {accounts}
                </Typography>
              </Card>
              
            </Box>
            <Box className="row">
              <Card className="dashboard-card  col-3"   style={{  backgroundColor: "lightskyblue"}}>
                <Typography variant="h6" component="h7">
                No of ExternalAccounts: {externalAccount}
                </Typography>
              </Card>

              <Card className="dashboard-card  col-3"   style={{ backgroundColor: "lightskyblue"}}>
                <Typography variant="h6" component="h7">
                No of ActiveExternalAccounts: {AccExternlAccount}
                </Typography>
              </Card>
              
            </Box>


            <Box className="row">
              <Card className="dashboard-card  col-3"   style={{ backgroundColor: "#488A99"}}>
                <Typography variant="h6" component="h9">
                No of Devices: {totaldevice}
                </Typography>
              </Card>

              <Card className="dashboard-card  col-3"   style={{ backgroundColor: "#488A99"}}>
                <Typography variant="h6" component="h9">
                No of LinkedDevice: {linkedDevice}
                </Typography>
              </Card>


              <Card className="dashboard-card  col-3"   style={{ backgroundColor: "#488A99"}}>
                <Typography variant="h6" component="h9">
                No of EnableDevice: {enableddevice}
                </Typography>
              </Card>
              
            </Box>
           
          </Box>
        )}
      </div>

    );
    
}
export default Dashboard;
