import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Home from './Component/Home';
import Login from './Component/Login';
import Navbar from './Component/Navbar';
import Register from './Component/Register';
import Subscription from './Component/Subscription';
import PrivateRoute from './PrivateRoute';
import MainNavbar from './MainNavbar';
import Sidebar from './Component/Sidebar';
import Admindashboard from './Admin/admindashboard';
import { AuthProvider, useAuth } from './auth/AuthContext';
import ForgotPassword from './Component/ForgotPassword';
import ResetPassword from './Component/ResetPassword';
import VehicleList from './Component/VehicleList';
import UserDevice from './Component/UserDevice';
import Publicuser from './Component/Publicuser';
import './App.css';
import PublicUsername from './Component/PublicUsername';
import VehicleDetails from './Component/VehicleDetails';
import Location from './Component/Location';
import Publicpage from './Component/publicpage';
import AddVehicle from './Component/Addvehicle';
import EditVehicledetails from './Component/Editvehicledetails';
import Vehiclereport from './Component/Vehiclereport';
import AccountSetting from './Component/AccountSetting';
import Profile from './Component/Profile';
import Notification from './Component/Notification';
import AccountSubscription from './Component/AccountSubscription';
import Security from './Component/Security';
import Payment from './Component/Payment';
import ExternalAccount from './Superadmin/ExternalAccount';
import Device from './Superadmin/Device';
import UserAcc from './Superadmin/UserAcc';
import AddDevice from './Superadmin/AddDevice';
import Subscriptionmodule from './Superadmin/Subscriptionmodule';
import Supersubscription from './Superadmin/Supersubscription';
import AddDeviceMaping from './Superadmin/AddDeviceMaping';
import Tax from './Superadmin/Tax';
import Features from './Superadmin/Features';
import Permission from './Superadmin/Permission';
import Account from './Superadmin/Account';
import Subscriptionregion from './Superadmin/Subscriptionregion';
import Editdevice from './Superadmin/Editdevice';
import PublicUserProfile from './Component/PublicUserProfile';
import LocationTable from './Component/LocationTable';
import Search from './Component/Search';
import Insurance from './Component/Insurance';
import Fuelmanagment from './Component/Fuelmanagment';
import Service from './Component/Service';
import Fitnesscertificate from './Component/Fitnesscertificate';
import Permit from './Component/Permit';
import EmissionCertificate from './Component/EmissionCertificate';
import Otp from './Component/Otp';
import Dashboard from './Superadmin/Dashboard';
import Public from './Component/publicpage';
import AccountBio from './Superadmin/AccountBio';

function App() {
  const { isAuthenticated, logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
 
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
 
  useEffect(() => {
    if (location.pathname === '/') {
      localStorage.removeItem("Profile")
      localStorage.removeItem("data");
      logout();
      navigate('/home');
    }
  }, [location, logout, navigate]);
 
  const isPublicPage = location.pathname.includes('/public');
 
  return (
    <div>
      {!isPublicPage && (isAuthenticated ? <MainNavbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> : <Navbar />)}
      <div className="app-container">
        {!isPublicPage && isAuthenticated && <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}
        <div className={`main-content ${isAuthenticated && isSidebarOpen ? '' : ''}`}>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register/:id" element={<Register />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/Otp/:id" element={<Otp />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/public/:link" element={<Public />} />
            <Route path="/users" element={<PrivateRoute />}>
              <Route path="Admindashboard" element={<Admindashboard />} />
              <Route path="UserDevice" element={<UserDevice />} />
    
              <Route path="publicuser" element={<Publicuser />} />
              <Route path="Location/:deviceid/:vehicleid/:colorCode" element={<Location />} />
              <Route path="Addvehicle" element={<AddVehicle />} />
              <Route path="Editvehicledetails/:id" element={<EditVehicledetails />} />
              <Route path="Vehicalereport/:vehicleid" element={<Vehiclereport />} />
              <Route path="PublicUsername/:userid" element={<PublicUsername />} />
              <Route path="VehicleDetails/:vehicleid" element={<VehicleDetails />} />
              <Route path="AccountSettings" element={<AccountSetting />} />
              {/* <Route path="Notification/:username" element={<Notification/>}/> */}
              <Route path="Security" element={<Security />} />
              <Route path="AccountSubscription" element={<AccountSubscription />} />
              <Route path="Notification" element={<Notification />} />
              <Route path="Payment" element={<Payment />} />
              <Route path="Profile" element={<Profile />} />
              <Route path="ExternalAccount" element={<ExternalAccount />} />
              <Route path="Device" element={<Device />} />
              <Route path="Insurance/:vehicleid" element={<Insurance />} />
              <Route path="Fuelmanagment/:vehicleid" element={<Fuelmanagment />} />
              <Route path="Service/:vehicleid" element={<Service />} />
              <Route path="Fitnesscertificate/:vehicleid" element={<Fitnesscertificate />} />
              <Route path="Permit/:vehicleid" element={<Permit />} />
              <Route path="EmissionCertificate/:vehicleid" element={<EmissionCertificate />} />
              <Route path="vehiclelist" element={<VehicleList />} />
              <Route path="vehiclelist/:id" element={<VehicleList />} />
 
 
              {/* <Route path="Userdevice" element={<UserDevice/>}/> */}
              <Route path="UserAcc" element={<UserAcc/>}/>
              <Route path="AddDevice" element={<AddDevice/>}/>
              <Route path="Subscriptionmodule" element={<Subscriptionmodule/>}/>
              <Route path="Supersubscription" element={<Supersubscription/>}/>
              <Route path="AddDeviceMaping" element={<AddDeviceMaping/>}/>
              <Route path="Tax" element={<Tax/>}/>
              <Route path="Features" element={<Features/>}/>
              <Route path="Permission" element={<Permission/>}/>
              <Route path="Account" element={<Account/>}/>
              <Route path="Subscriptionregion" element={<Subscriptionregion/>}/>
              <Route path="Editdevice/:id" element={<Editdevice/>}/>
              <Route path="PublicUserProfile/:userid" element={<PublicUserProfile/>}/>
              <Route path="LocationTable/:deviceid/:vehicleid" element={<LocationTable/>}/>
              <Route path="Search" element={<Search/>}/>
              <Route path="Dashboard" element={<Dashboard/>}/>
              <Route path="AccountBio" element={<AccountBio/>}/>

              
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}
 
const AppWithProvider = () => (
  <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>
);
 
export default AppWithProvider;