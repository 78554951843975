import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    InputAdornment,
    Pagination,
} from '@mui/material';
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';
import axios from '../api/baseurl';


function Subscriptionregion() {
    const token = JSON.parse(localStorage.getItem('data')).token;
    const Apiurl = process.env.REACT_APP_BASE_URL;
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
    const [loading, setLoading] = useState(false);
    const [reg, setReg] = useState([]);
    const [filteredReg, setFilteredReg] = useState([]);
    const [regions, setRegions] = useState({
        region: '',
        currency: '',
        symbol: '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const itemsPerPage = 9;
    

    const getData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${Apiurl}/api/subscription-region`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setReg(data);
            setFilteredReg(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleChange = (event, field) => {
        const actualValue = event.target.value;
        setRegions({ ...regions, [field]: actualValue });
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        const filtered = reg.filter(
            (item) =>
                item.region.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.currency.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.symbol.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setFilteredReg(filtered);
        setCurrentPage(1);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await axios.post(`${Apiurl}/api/subscription-region/create`, regions, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success('Subscription region added');
            setRegions({ region: '', currency: '', symbol: '' }); // Clear form fields
            getData(); // Refresh the data after successful submission
            forceUpdate();
            setOpen(false); // Close the dialog
        } catch (error) {
            toast.error('The region must be unique');
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredReg.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div>
            <div id="layoutDrawer_content">
                {loading && (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
                {!loading && (
                    <main>
                        <div className="row justify-content-center mt-4">
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} sm={8} md={6} >
                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        fullWidth
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <SearchIcon  />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8} md={6} style={{ textAlign: 'right' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        onClick={openDialog}
                                    >
                                        Add 
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="mt-4">
                                {currentItems.map((region) => (
                                    <Grid item xs={12} sm={6} md={4} key={region.id}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h6">{region.region}</Typography>
                                                <Typography color="textSecondary">{region.currency}</Typography>
                                                <Typography color="textSecondary">{region.symbol}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                            <br/>
                            <Grid container justifyContent="center" className="mt-4">
                                <Pagination
                                    count={Math.ceil(filteredReg.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </Grid>
                        </div>
                    </main>
                )}
            </div>

            <Dialog open={open} onClose={closeDialog}>
                <DialogTitle>Add New Subscription Region</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            margin="dense"
                            id="region"
                            label="Region"
                            type="text"
                            fullWidth
                            value={regions.region}
                            onChange={(e) => handleChange(e, 'region')}
                            required
                        />
                        <TextField
                            margin="dense"
                            id="currency"
                            label="Currency"
                            type="text"
                            fullWidth
                            value={regions.currency}
                            onChange={(e) => handleChange(e, 'currency')}
                            required
                        />
                        <TextField
                            margin="dense"
                            id="symbol"
                            label="Symbol"
                            type="text"
                            fullWidth
                            value={regions.symbol}
                            onChange={(e) => handleChange(e, 'symbol')}
                            required
                        />
                        <DialogActions>
                            <Button onClick={closeDialog} color="secondary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Subscriptionregion;
