import React, { useState, useEffect, useRef, useCallback } from 'react';
import Chart from 'chart.js/auto';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Select, MenuItem, InputLabel, FormControl, Button, TextField } from '@mui/material';
import './BarChart.css'; 

const Apiurl = process.env.REACT_APP_BASE_URL;

const BarChart = ({ className }) => {
  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  const [deviceIds, setDeviceIds] = useState([]);
  const [distances, setDistances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDeviceId, setSelectedDeviceId] = useState('None');
  const [date1, setDate1] = useState(moment().format("YYYY-MM-DD"));
  const [date2, setDate2] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    fetchDataForCurrentDate();
  }, []);

  const fetchDataForCurrentDate = async () => {
    setLoading(true);
    const storedData = JSON.parse(localStorage.getItem('data'));
    if (storedData && storedData.token && storedData.userid) {
      const token = storedData.token;
      const userId = storedData.userid;
      try {
        // Fetch the devices for the user
        const devicesResponse = await axios.get(`${Apiurl}/api/devicevehicle/userid=${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const devices = devicesResponse.data;

        const startdate = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const enddate = dayjs().subtract(10, 'minutes').format('YYYY-MM-DDTHH:mm:ss');

        const distanceResponse = await axios.post(
          `${Apiurl}/api/location/distance/userid=${userId}`,
          { startdate, enddate },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Extract device IDs and distances
        const deviceIds = devices.map(device => device.deviceid);
        const distances = deviceIds.map(deviceId => distanceResponse.data[deviceId] || 0);

        setDeviceIds(['None', ...deviceIds]); // Add "None" option
        setDistances(distances);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);  
      }
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const renderChart = useCallback((labels, data) => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext('2d');
    
    if (chartRef.current) {
      chartRef.current.destroy(); // Destroy previous chart instance if it exists
    }
    
    const colors = labels.map(() => getRandomColor());
    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Distance',
            data,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            onClick: () => {},
          },
          title: {
            display: true,
            text: 'Distance vs. Device IDs',
          },
        },
        scales: {
          x: {
            min: 0,
            max: 60,
            ticks: {
              stepSize: 5,
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.1)',
              borderWidth: 0.5,
              borderDash: [3, 3],
            },
          },
          y: {
            grid: {
              color: 'rgba(0, 0, 0, 0.1)',
              borderWidth: 0.5,
              borderDash: [3, 3],
            },
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    if (!loading && deviceIds.length > 0 && distances.length > 0) {
      renderChart(deviceIds, distances);
    }
  }, [deviceIds, distances, loading, renderChart]);

  const filterDistance = async () => {
    const storedData = JSON.parse(localStorage.getItem('data'));
    if (storedData && storedData.token && storedData.userid) {
      const token = storedData.token;
      const userId = storedData.userid;
      try {
        if (selectedDeviceId === 'None') {
          fetchDataForCurrentDate();
        } else {
          const startdate = moment(date1).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
          const enddate = moment(date2).endOf('day').format('YYYY-MM-DDTHH:mm:ss');

          const distanceResponse = await axios.post(
            `${Apiurl}/api/location/distance/deviceid=${selectedDeviceId}`,
            { startdate, enddate },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const newDistanceData = distanceResponse.data;

          renderChart([selectedDeviceId], [newDistanceData]);
        }
      } catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    }
  };

  const handleChange = (e, field) => {
    if (field === 'startdate') {
      setDate1(e.target.value);
    } else if (field === 'enddate') {
      setDate2(e.target.value);
    } else if (field === 'deviceId') {
      setSelectedDeviceId(e.target.value);
    }
  };

  return (
    <div className="container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="chart-container">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="device-id-label">Device Id</InputLabel>
                <Select
                  labelId="device-id-label"
                  id="device-id"
                  value={selectedDeviceId}
                  onChange={(e) => handleChange(e, "deviceId")}
                >
                  {deviceIds.map((id) => (
                    <MenuItem key={id} value={id}>
                      {id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth 
                value={date1}
                onChange={(e) => handleChange(e, 'startdate')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField  
                label="End Date"
                type="date"
                fullWidth
                value={date2}
                onChange={(e) => handleChange(e, 'enddate')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={filterDistance}
                startIcon={<SearchIcon />}
                style={{ width: "100px" }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <canvas ref={canvasRef} id="barChart"></canvas>
        </div>
      )}
    </div>
  );
};

export default BarChart;
